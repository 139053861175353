<template>
    <div id="app">
        <Navbar :email="$store.state.auth.email" :fixed="$route.name === 'report'" />
        <router-view />
    </div>
</template>

<script>
import Navbar from '@/shared/components/Navbar'
export default {
    components: {
        Navbar,
    },
}
</script>

<style src="@/assets/style/index.scss" lang="scss"></style>

<style lang="scss">
h1,
h2,
h3 {
    font-family: 'Montserrat', sans-serif;
}
</style>
