import URI from 'urijs'

const utils = {}
utils.epc_mapping = {
    Wallonia: {
        'A++': -20,
        'A+': 22.5,
        A: 65,
        B: (86 + 170) / 2,
        C: (170 + 255) / 2,
        D: (255 + 340) / 2,
        E: (340 + 425) / 2,
        F: (425 + 510) / 2,
        G: 550,
    },
    Flanders: {
        'A+': -20,
        A: 50,
        B: 150,
        C: 250,
        D: 350,
        E: 450,
        F: 550,
    },
    Brussels: {
        A: 22.5,
        B: (25 + 95) / 2,
        C: (95 + 150) / 2,
        D: (150 + 210) / 2,
        E: (210 + 275) / 2,
        F: (275 + 345) / 2,
        G: 450,
    },
}

utils.value_formatter = new Intl.NumberFormat('fr-BE', {
    style: 'currency',
    currency: 'EUR',
    maximumSignificantDigits: 3,
}).format

utils.quantile_formatter = (value) => {
    if (value > 0.5) {
        return `top ${Math.ceil((1 - value) * 20) * 5}%`
    } else {
        return `bottom ${Math.ceil(value * 20) * 5}%`
    }
}

utils.isEmptyStr = (str) => {
    return !str.replace(/\s/g, '').length
}

utils.urlJoin = (baseUrl, paths) => {
    var fullPath = ''

    if (!(baseUrl.startsWith('/') || baseUrl.startsWith('http'))) {
        fullPath += '/'
    }

    if (baseUrl.endsWith('/')) {
        fullPath += baseUrl.slice(0, -1)
    } else {
        fullPath += baseUrl
    }

    if (!Array.isArray(paths)) {
        if (!paths.startsWith('/')) {
            fullPath += '/'
        }

        fullPath += paths

        try {
            var returnURI = new URI(fullPath)

            return returnURI.toString()
        } catch (e) {
            throw new Error('Invalid parameters for urlJoin function.')
        }
    } else {
        if (paths[0] === null) {
            var remainingPaths = [...paths]
            remainingPaths.shift()

            return utils.urlJoin(baseUrl, remainingPaths)
        } else {
            if (!paths[0].startsWith('/')) {
                fullPath += '/'
            }

            fullPath += paths[0]

            try {
                var returnURI = new URI(fullPath)

                if (paths.length === 1) {
                    return returnURI.toString()
                }
            } catch (e) {
                throw new Error('Invalid parameters for urlJoin function.')
            }

            var remainingPaths = [...paths]
            remainingPaths.shift()

            return utils.urlJoin(returnURI.toString(), remainingPaths)
        }
    }
}

utils.parseJwt = (token) => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

utils.getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    }
    return undefined
}

utils.deleteCookie = (name) => {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
}

utils.formatDate = (now) => {
    return `${now.getFullYear()}-${(
        '0' +
        now.getMonth() +
        1
    ).slice(-2)}-${('0' + now.getDate()).slice(
        -2
    )}-${('0' + now.getHours()).slice(
        -2
    )}-${('0' + now.getMinutes()).slice(
        -2
    )}`
}

utils.arrayToggle = (array, value) => {
    var index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

// https://stackoverflow.com/a/3464346 but modified because it made some mistakes or didn't conform to what we're trying to achieve
utils.dateDiffInBusinessDays = (dDate1, dDate2) => {
    var iWeeks, iDateDiff, iAdjust = 0;
    if (dDate2 < dDate1) return -1; // error code if dates transposed
    var iWeekday1 = dDate1.getDay(); // day of week
    var iWeekday2 = dDate2.getDay();
    iWeekday1 = (iWeekday1 == 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
    iWeekday2 = (iWeekday2 == 0) ? 7 : iWeekday2;
    if (iWeekday1 > 5) iAdjust = 1;
    iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
    iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;
  
    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)
  
    if (iWeekday1 <= iWeekday2) {
      iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1)
    } else {
      iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2)
    }
  
    iDateDiff -= iAdjust
  
    return (iDateDiff);
}

utils.dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export default utils
