var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{class:{ 'is-fixed-top': _vm.fixed },attrs:{"id":"navbar","type":"is-white"}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":_vm.email
                    ? {
                          name: 'request',
                          params: { action: 'start', lang: this.$i18n.locale },
                      }
                    : { name: 'login', params: { lang: this.$i18n.locale } }}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/icons/rockestate-logo-horizontal-dark.svg"),"width":"148"}})])],1),_c('template',{slot:"end"},[_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('div',{staticClass:"langs buttons has-addons my-0"},[_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': this.$route.params.lang === 'nl-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('nl-BE')}}},[_c('span',[_vm._v("NL")])]),_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': this.$route.params.lang === 'fr-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('fr-BE')}}},[_c('span',[_vm._v("FR")])]),_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': this.$route.params.lang === 'en-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('en-BE')}}},[_c('span',[_vm._v("EN")])])],1),_c('div',{staticClass:"mr-4 px-1 is-size-7 user",class:[_vm.email ? 'ml-6' : 'ml-4']},[_vm._v(" "+_vm._s(_vm.email)+" ")]),_c('div',{staticClass:"buttons"},[(_vm.email != null)?_c('a',{attrs:{"href":_vm.logout_url}},[_c('b-button',{attrs:{"size":"is-small","type":"is-primary","outlined":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('logout')))])])],1):_vm._e()])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }