import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as VueGoogleMaps from 'gmap-vue'
import i18n from '@/shared/i18n'

// Leaflet stuff
import { LMap, LTileLayer, LMarker, LGeoJson, LControlLayers } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

Vue.component('LMap', LMap)
Vue.component('LTileLayer', LTileLayer)
Vue.component('LMarker', LMarker)
Vue.component('LGeoJson', LGeoJson)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
Vue.config.productionTip = false

import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'
Vue.use(Buefy)
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: 'places',
    },
})

import Axios from '@/shared/plugins/axios'
Vue.prototype.$axios = Axios
import store from './store'
Vue.prototype.$store = store
window.store = store

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

if (typeof process.env.VUE_APP_SENTRY_DSN != 'undefined') {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new VueIntegration({ Vue, attachProps: true })],
        environment: process.env.VUE_APP_ENVIRONMENT,
        release: process.env.VUE_APP_FRONTEND_VERSION,
    })
}

router.beforeEach((to, from, next) => {
    if (to.params.lang) {
        i18n.locale = to.params.lang
    }
    next()
})

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
